<template>
	<div class="address-list" v-if="isLoad">
		<template v-if="list.length > 0">
			<div class="address-grid" v-for="(item, index) in list" :key="index" @click="checkAddress(item)">
				<div class="address-hd">
					<div class="nickname">{{ item.contacts }}</div>
					<div class="phone">{{ item.contact_phone }}</div>
					<div class="address">{{ item.region + item.address }}</div>
				</div>
				<div class="address-bd">
					<div class="left">{{ item.is_default | defaultText }}</div>
					<div class="right">
						<div class="btn" @click.stop="editAddress(item.address_id)">
							<i class="iconfont icon-bianji"></i>
							<span>编辑</span>
						</div>
						<div class="btn" @click.stop="delAddress(item.address_id)">
							<i class="iconfont icon-shanchu"></i>
							<span>删除</span>
						</div>
					</div>
				</div>
			</div>
			<load-more :loadLastText="loadLastText" @getMore="getAddressList"></load-more>
		</template>
		<no-data v-else content="还没有地址，快去添加吧"></no-data>

		<router-link to="/AddressEdit?type=add" tag="div" class="btn-sub">添加收货地址</router-link>
	</div>
</template>
<script>
	import {
		getAddressList,
		removeAddress
	} from "@/http/api";

	export default {
		components: {},
		data() {
			return {
				userToken: "",
				from: 'user',
				pages: 1,
				loadLastText: true,
				isLoad: false,
				list: []
			};
		},
		mounted() {
			if (this.$route.query.from) {
				this.from = this.$route.query.from;
			}
			this.userToken = this.$LStorage.getItem("userToken").user_token;
			this.getAddressList(); //获取地址列表
		},
		methods: {
			// 重置列表数据
			resetList() {
				this.list = [];
				this.pages = 1;
				this.isLoad = false;
				this.loadLastText = true;
				
				this.getAddressList();
			},
			// 获取地址列表
			async getAddressList() {
				const res = await getAddressList({
					data: {
						user_token: this.userToken,
						pages: this.pages
					}
				});
				// console.log(res);
				if (res.code == 200) {
					this.isLoad = true;
					this.list = this.list.concat(res.data);
					if (res.data.length < res.numPage) {
						this.loadLastText = false;
					} else {
						this.pages++;
						this.loadLastText = true;
					}
					if (this.list.length == 0) {
						this.$store.dispatch("changeAddressInfo", null);
					}
				}
			},
			
			// 删除地址
			async delAddress(addressId) {
				this.$dialog.confirm({
					title: "提示",
					message: "确认要删除这个地址吗？"
				}).then(() => {
					removeAddress({
						data: {
							user_token: this.userToken,
							address_id: addressId
						}
					}).then(res => {
						if (res.code == 200) {
							this.$toast.success("删除成功");
							this.resetList();
						} else {
							this.$toast(res.msgs);
						}
					});
				}).catch(() => {
					// on cancel
				});
			},
			
			// 编辑地址
			editAddress(address_id) {
				this.$router.push({
					path: "/AddressEdit?type=edit&id=" + address_id
				});
			},
			
			// 选择地址
			checkAddress(data) {
				if (this.from == "order") {
					this.$store.dispatch("changeAddressInfo", data);
					this.$router.go(-1);
				}
			}
		},
		computed: {},
		filters: {
			defaultText(data) {
				if (data == 1) {
					return "默认地址";
				}
			}
		}
	};
</script>
<style lang="scss" scoped>
	.address-list {
		* {
			box-sizing: border-box;
		}

		min-height: 100vh;
		width: 100vw;
		background: #f6f6f6;
		padding: 3vw 0;
		display: flex;
		flex-direction: column;
		align-items: center;

		.btn-sub {
			position: fixed;
			bottom: 6vw;
			left: 3vw;
			width: 94vw;
			height: 0.75rem;
			border-radius: 0.75rem;
			background: #e50012;
			color: #fff;
			font-size: 0.32rem;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.address-grid {
			width: 94vw;
			background: #fff;
			padding: 0 4vw;
			box-sizing: border-box;
			margin-bottom: 10px;

			.address-hd {
				height: 18vw;
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;
				border-bottom: 1px solid #eee;

				font-size: 0.28rem;
				color: #020202;

				.address {
					width: 100%;
					font-size: 0.26rem;
					color: #8c8c8c;
				}
			}

			.address-bd {
				height: 12vw;

				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: 0.26rem;
				color: #666;

				.left {
					color: #e50012;
				}

				.dui-img {
					width: 6vw;
				}

				.right {
					display: flex;

					.btn {
						display: flex;
						align-items: center;
						margin-left: 0.45rem;

						.iconfont {
							margin-right: 0.1rem;
							font-size: 0.3rem;
						}
					}
				}
			}
		}
	}
</style>
